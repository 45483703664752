import "@hotwired/turbo-rails"
import "@popperjs/core"
import "bootstrap"
import "daterangepicker"

import "./controllers/index.js"
import "./lib/theme.js"


console.log("STIMULUS", window.Stimulus)



