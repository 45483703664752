// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application.js"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const context = require.context("./", true, /\.js$/)
const definitions = definitionsFromContext(context)
console.log({ definitions })
application.load(definitions)
window.Stimulus = application
