import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {

    const earningsChart = document.getElementById('earningsChart');

    console.log({ earningsChart })

    if (earningsChart) {
        new window.Chart(earningsChart, {
            type: 'bar',
            options: {
            scales: {
                yAxisOne: {
                display: 'auto',
                ticks: {
                    callback: function (value) {
                    return '$' + value + 'k';
                    },
                },
                },
                yAxisTwo: {
                display: 'auto',
                ticks: {
                    callback: function (value) {
                    return value + 'k';
                    },
                },
                },
                yAxisThree: {
                display: 'auto',
                ticks: {
                    callback: function (value) {
                    return value + '%';
                    },
                },
                },
            },
            },
            data: {
            labels: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
            ],
            datasets: [
                {
                label: 'Earnings',
                data: [18, 10, 5, 15, 10, 20, 15, 25, 20, 26, 25, 29, 18, 10, 5, 15, 10, 20],
                yAxisID: 'yAxisOne',
                },
                {
                label: 'Sessions',
                data: [50, 75, 35, 25, 55, 87, 67, 53, 25, 80, 87, 45, 50, 75, 35, 25, 55, 19],
                yAxisID: 'yAxisTwo',
                hidden: true,
                },
                {
                label: 'Bounce',
                data: [40, 57, 25, 50, 57, 32, 46, 28, 59, 34, 52, 48, 40, 57, 25, 50, 57, 29],
                yAxisID: 'yAxisThree',
                hidden: true,
                },
            ],
            },
        });
    }

  }
}
